import $ from 'cash-dom'

let resizeTimeout = null

function updateHandlePosition(content) {
  const scrollPosition = content[0].scrollLeft
  const contentInner = content.find('.scrollable-content-inner')
  const handle = content.closest('.scrollable').find('.scrollable-scrollbar-handle')
  let factor = contentInner.width() / content.width()
  
  let handlePosition = (scrollPosition / factor)

  if (handlePosition > content.width() - handle.width()) {
    handlePosition = content.width() - handle.width()
  }

  handle.css({
    left: `${handlePosition}px`
  })
}

function setHandleWidth(component) {
  if (!component || (component && component.length === 0)) {
    return
  }

  const content = component.find('.scrollable-content-inner')
  const handle = component.find('.scrollable-scrollbar-handle')

  if (content.length === 0 || handle.length === 0) {
    return
  }

  let handleWidth = component.width() / content.width() * 100

  handle.css({
    width: `${handleWidth}%`
  })
}

function initScrollables() {
  $('.scrollable').each((index, obj) => {
    const component = $(obj)
    setHandleWidth(component)
    updateHandlePosition(component.find('.scrollable-content'))
    

    component.find('.scrollable-content').on('scroll', (e) => {
      updateHandlePosition($(e.target))
    })
  })
}

function navigate(direction, button) {
  const component = button.closest('.scrollable')
  const content = component.find('.scrollable-content')
  const contentInner = component.find('.scrollable-content-inner')

  let scrollPosition = 0

  if (direction === 'right') {
    scrollPosition = content.width() + content[0].scrollLeft
    
    if (scrollPosition > contentInner.width() - content.width()) {
      scrollPosition = contentInner.width() - content.width()
    }
  } else {
    scrollPosition = (content.width() * -1) + content[0].scrollLeft
    if (scrollPosition < 0) {
      scrollPosition = 0
    }
  }

  content[0].scrollTo({
    top: 0,
    left: scrollPosition,
    behavior: 'smooth'
  })
}

// Event handlers

$(window).on('load', initScrollables)

$(window).on('resize.scrollable', () => {
  if (resizeTimeout) {
    clearTimeout(resizeTimeout)
  }

  resizeTimeout = setTimeout(initScrollables, 200)
})

$(document).on('click', '.scrollable-scrollbar', (e) => {
  const scrollBar = $(e.target)
  const handle = scrollBar.find('.scrollable-scrollbar-handle')
  const component = scrollBar.closest('.scrollable')
  const content = component.find('.scrollable-content')
  const contentInner = component.find('.scrollable-content-inner')

  let factor = contentInner.width() / component.width()
  let clickPosition = e.clientX - component[0].offsetLeft

  let scrollPosition = (clickPosition * factor) - handle.width()
  if (scrollPosition < 0) {
    scrollPosition = 0
  }

  content[0].scrollTo({
    top: 0,
    left: scrollPosition,
    behavior: 'smooth'
  })
})

$(document).on('click', '.scrollable-nav-item', (e) => {
  const button = $(e.currentTarget)
  button.hasClass('is-right') ? navigate('right', button) : navigate('left', button)
})